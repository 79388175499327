import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BuilderPage from './BuilderPage';
import './builder-components';

function App() {
    return (
        <Router>
            <Routes>
                {/* Redirect from the root to /login */}
                <Route path="/" element={<Navigate to="/login" />} />

                {/* Dynamic route that loads JSON based on the URL */}
                <Route path="/:jsonFile" element={<BuilderPage />} />
            </Routes>
        </Router>
    );
}

export default App;
